import React, { useEffect, useState } from "react";
import './Builder.css';
import Popup from "../components/BuilderUnit-popup";
import Table from "../components/Table";
import DashboardLayout from '../components/DashboardLayout';
import { getAllBuilders, getUnitsByBuilder } from "../serviceAPI/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import viewImage from './view.png';

const Building = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [editIndex, setEditIndex] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [heading, setHeading] = useState([]);
    const navigate = useNavigate()

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setEditIndex(null);
    };

    useEffect(() => {
        getBuilderUnitData();
    }, []);

    const getBuilderUnitData = async () => {
        const builderData = await getUnitsByBuilder();
        if (builderData?.data && builderData?.data?.length) {
            // Add view image to each data row
            const processedData = builderData.data.map(item => ({
                ...item,
                view: <a><img className="building-view" src={viewImage} alt="View" style={{ cursor: 'pointer' }} onClick={() => handleViewClick(item)} /></a>
            }));
            setData(processedData);
        } else {
            setData([]);
        }
        setHeading([{ key: 'unit_name', head: 'Unit Name' }, { key: 'address', head: 'Address' }, { key: 'builderuser.company_name', head: 'Company Name' }, { key: 'unit_showroomCount', head: 'Showroom' }, { key: 'unit_officeCount', head: 'Office' }, { key: 'unit_flatCount', head: 'Flat' }, { key: 'unit_hosueCount', head: 'House' }, { key: 'view', head: 'View' }]);
    };

    const handleEdit = (index) => {
        setEditIndex(index);
        setIsPopupOpen(true);
    };

    const fetchApi = () => {
        getBuilderUnitData();
    };

    const handleSort = (column) => {
        const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(direction);
    };

    const handleViewClick = (item) => {
        // Add your view click handling logic here
        console.log('View clicked for item:', item);
        navigate(`/unit/${item.unit_id}`)
    };

    return (
        <DashboardLayout>
            <div className='title-btn'>
                <h1>Building</h1>
                <button className="btn dashboard-btn" onClick={togglePopup}>Add</button>
                {isPopupOpen && <Popup
                    isOpen={isPopupOpen}
                    onClose={togglePopup}
                    fetchApi={fetchApi}
                    initialData={editIndex !== null ? data[editIndex] : null}
                />}
            </div>
            {data.length > 0 && (
                <Table
                    headings={heading}
                    data={data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    handleEdit={handleEdit}
                    handleSort={handleSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    setItemsPerPage={setItemsPerPage}
                />
            )}
        </DashboardLayout>
    );
};

export default Building;
