import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addBuilderUser, updateBuilderUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const addToast = useToaster()
  console.log(initialData)
  const [formValues, setFormValues] = useState({
    name: '',
    builderName: '',
    mail: ''
  });

  useEffect(() => {
    if (initialData && initialData.user_id) {
      setFormValues({
        name: initialData?.username,
        builderName: initialData['builderuser.company_name'],
        number: initialData?.mobileNumber,
        mail: initialData?.email
      });
    }else{
      setFormValues({
        name: '',
        builderName: '',
        number: '',
        mail: ''
      });
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const regex = /^\d{10}$/;
    if (!regex.test(formValues.number)) {
      alert('mobile number invalid')
      return
    }

    const userData = {
      username:formValues.name.trim(),
      phone_number: formValues.number,
      email:formValues.mail,
      company_name: formValues.builderName
    };

    if (initialData && initialData.user_id) {
      userData.user_id = initialData.user_id
      const response = await updateBuilderUser(userData,addToast);
      if (response.status) {
        // setMessage('Builder User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    } else {
      const response = await addBuilderUser(userData,addToast);
      if (response.status) {
        // setMessage('Builder User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    }
    // addEntry(newEntry);
    // onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className='popup-header d-flex justify-content-between align-items-center'>
          <h2>{initialData ? 'Edit Entry' : 'Add Entry'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <input type="text" name="name" className='form-control' placeholder='Person Name' value={formValues.name} onChange={handleInputChange} required />
            </div>
            <div className='col-md-6 mb-4'>
              <input type="text" name="builderName" className='form-control' placeholder='Builder Name' value={formValues.builderName} onChange={handleInputChange} required />
            </div>
            <div className='col-md-6 mb-4'>
              <input type="number" name="number" className='form-control' placeholder='Number' value={formValues.number} onChange={handleInputChange} required />
            </div>
            <div className='col-md-6 mb-4'>
              <input type="email" name="mail" className='form-control' placeholder='E-mail' value={formValues.mail} onChange={handleInputChange} />
            </div>
          </div>
          <button className='btn btn-blue' type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
