import React from 'react';
import './Scrollbar.css';

const Scrollbar = () => {
  return (
    <div className="scroll-parent">
      <marquee>Secure Your Future with Confidence. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Protecting What Matters Most.</marquee>
    </div>
  );
}

export default Scrollbar;
