import React from 'react';
import './BlogHome.css';
import { Link } from 'react-router-dom';

const BlogHome = () => {
    return (
        <>
        <div className='col-lg-4'>
            <div className='blog-box'>
                <div className='blog-box-img'>
                    <img src='/Assets/are-you-covered.jpg' className='img-fluid'></img>
                </div>
                <div className='blog-box-content'>
                    <h3>Blog 1</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    <Link to='/bloglist1' className='read-more'>Read More <i class="bi bi-arrow-right"></i></Link>
                </div>
            </div>
        </div>
                    <div className='col-lg-4'>
                        <div className='blog-box'>
                            <div className='blog-box-img'>
                                <img src='/Assets/738.jpg' className='img-fluid'></img>
                            </div>
                            <div className='blog-box-content'>
                                <h3>Blog 2</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                <Link to='/bloglist2'>Read More <i class="bi bi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='blog-box'>
                            <div className='blog-box-img'>
                                <img src='/Assets/738.jpg' className='img-fluid'></img>
                            </div>
                            <div className='blog-box-content'>
                                <h3>Blog 3</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                <Link to='/bloglist3'>Read More <i class="bi bi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                
        
        </>
    );
};

export default BlogHome;