import React from 'react';
import './About.css';
import Breadcrumb from '../components/Breadcrumb.js';
import BlogHome from "./BlogHome.js";
import Clientslideraboutpage from '../components/Clientslideraboutpage.js';
import Footer from '../components/Footer.js';

const Services = () => {
    return (
        <>
        <Breadcrumb />
        <section className="contact-info">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="contact-info-content">
                            
                            <div className="img">
                                <img src="./Assets/happy-family.jpg" className="img-fluid"/>
                                <div className="experience">
                                    <h4>25  Years of</h4>
                                    <p>Experience</p>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-info-right-content">
                            <h1 className="heading">Free your youth for a brighter and more purposeful future</h1>
                            <p>Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Maecenas ultricies mi eget mauris. Odio morbi quis commodo odio aenean sed adipiscing diam.</p>
                            <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Accordion Item #1
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="about-content">
                            <a href="#" className="btn mb-4">About us</a>
                            <h1 className="heading">We provide the best solutions to guarantee the future for you or your family!</h1>
                            <p>Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Maecenas ultricies mi eget mauris. Odio morbi quis commodo odio aenean sed adipiscing diam.</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="about-right-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='count'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Worldwide customers</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Worldwide customers</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Worldwide customers</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Worldwide customers</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="blog">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="blog-heading text-center">
                        <a href="#" className="btn mb-4">Blog</a>
                        <h1 className="heading">Recent Blogs</h1>
                        </div>
                    </div>
                    <BlogHome />
                </div>
            </div>
        </section>
        <Clientslideraboutpage />
        <Footer />
        </>
    );
};

export default Services;
