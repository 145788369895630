import './Services.css';
import React, { useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Clientslider from "../components/Clientslider.js";
import BlogHome from './BlogHome';
import Footer from "../components/Footer";

const Services = () => {
    
    return  (
      <>
      <Breadcrumb />
      <div className='services'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='services-heading'>
                <h1 className='heading'>Insurance services that have accompanied since 1995</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='services-img'>
                <img src="./Assets/happy-family.jpg" className="img-fluid"/>
                <div className="experience">
                  <h4>25  Years of</h4>
                  <p>Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Clientslider />
      <section className='service-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='service-info-heading'>
                <h1 className='heading text-center'>The focus of our insurance services</h1>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='service-box'>
                <h3>Life Insurance</h3>
                <p>Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='service-box'>
                <h3>Life Insurance</h3>
                <p>Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='service-box'>
                <h3>Life Insurance</h3>
                <p>Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='service-box'>
                <h3>Life Insurance</h3>
                <p>Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non consectetur a erat nam at. Sit amet risus nullam eget felis eget nunc lobortis mattis.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="blog-heading text-center">
              <a href="#" className="btn mb-4">Blog</a>
              <h1 className="heading">Recent Blogs</h1>
            </div>
          </div>
          <BlogHome />
        </div>
      </div>
    </section>
      <Footer />
      </>
    )    
};

export default Services;
