import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import Cookies from 'js-cookie';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoanDropdownOpen, setIsLoanDropdownOpen] = useState(false);
  const [isLoanDropdownOpenMed, setIsLoanDropdownOpenMed] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const location = useLocation();

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleLoanDropdown = (event) => {
    event.preventDefault();
    setIsLoanDropdownOpen(!isLoanDropdownOpen);
  };

  const toggleLoanDropdownMed = (event) => {
    event.preventDefault();
    setIsLoanDropdownOpenMed(!isLoanDropdownOpenMed);
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const categoryId = Cookies.get('category');
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';


  const handleLinkClick = () => {
    // Use toggleSidebar instead of setIsSidebarOpen
    toggleSidebar();
  };

  useEffect(() => {
    // Initialize all tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].forEach(tooltipNode => new window.bootstrap.Tooltip(tooltipNode));
  }, []);

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className='side-menu'>
          <div className='sidebar-header'>
            <img className='sidebar-logo img-fluid' src='../Assets/logo.png' alt='logo' />
            <div className={`toggle-button toggle-icon my-auto ${isOpen ? 'rotated' : ''}`} onClick={toggleSidebar}>
              <i className="bi bi-arrow-bar-left"></i>
            </div>
          </div>
          <ul className='menu' id="sidemenu">
            <li className={`${activeTab === '/dashboard' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Dashboard">
              <Link to="/dashboard" className='s-btn' onClick={handleLinkClick}>
                <i className="bi bi-speedometer2 me-3"></i><span>Dashboard</span>
              </Link>
            </li>
            <li className={`${activeTab === '/consumer' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Consumer">              
              {(user && user.role_id === 1)&& <Link to="/consumer" className='s-btn' onClick={handleLinkClick}>
              <i className="bi bi-person me-3"></i><span>Consumer</span>
              </Link>}
            </li>
            {(user && (user.role_id === 1 || user.role_id === 2)) && (
              <li className={`${activeTab === '/builder' && 's-btn-active'} dropdown-container`} data-bs-toggle="tooltip" data-bs-placement="right" title="Builder">                
                <Link to="/builder" className='s-btn' onClick={handleLinkClick}>
                <i className="bi bi-buildings me-3"></i><span>Builder</span>                  
                </Link>
                <span className={`arrow ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}></span>
                {isDropdownOpen && (
                  <ul className='dropdown'>
                    <li className={`${activeTab === '/builder/building' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Building">
                      <Link to="/builder/building" className='s-btn' onClick={handleLinkClick}>
                        <i className="bi bi-buildings me-3"></i><span>Building</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}
            {((user && user.role_id === 1) || (categoryId && categoryId.includes(2))) && (
              <li  className={`${activeTab === '/loan' && 's-btn-active'} dropdown-container`} data-bs-toggle="tooltip" data-bs-placement="right" title="Loan">
                <Link to="/loan" className='s-btn' onClick={handleLinkClick}>
                <i className="bi bi-cash-coin me-3"></i><span>Loan</span>                   
                </Link>
                <span className={`arrow ${isLoanDropdownOpen ? 'open' : ''}`} onClick={toggleLoanDropdown}></span>
                {isLoanDropdownOpen && (
                  <ul className='dropdown'>
                    <li className={`${activeTab === '/loan/interested' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Interested">
                      <Link to="/loan/interested" className='s-btn' onClick={handleLinkClick}>
                        <i className="bi bi-cash-coin me-3"></i><span>Interested</span>
                      </Link>
                    </li>
                    <li className={`${activeTab === '/loan/not-interested' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Not Interested">
                      <Link to="/loan/not-interested" className='s-btn' onClick={handleLinkClick}>
                        <i className="bi bi-cash-coin me-3"></i><span>Not Interested</span>
                      </Link>
                    </li>
                    <li className={`${activeTab === '/loan/disburse' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Disburse">
                      <Link to="/loan/disburse" className='s-btn' onClick={handleLinkClick}>
                        <i className="bi bi-cash-coin me-3"></i><span>Disburse</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}
            {((user && user.role_id === 1) || (categoryId && categoryId.includes(4))) && (
              <li className={`${activeTab === '/mediclaim' && 's-btn-active'} dropdown-container`} data-bs-toggle="tooltip" data-bs-placement="right" title="Mediclaim">
                <Link to="/mediclaim" className='s-btn' onClick={handleLinkClick}>
                  <i className="bi bi-capsule-pill me-3"></i><span>Mediclaim</span>                  
                </Link>
                <span className={`arrow ${isLoanDropdownOpenMed ? 'open' : ''}`} onClick={toggleLoanDropdownMed}></span>
                {isLoanDropdownOpenMed && (
                  <ul className='dropdown'>
                    <li className={`${activeTab === '/mediclaim/company' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Company">
                      <Link to="/mediclaim/company" className='s-btn' onClick={handleLinkClick}>
                        <i className="bi bi-building me-3"></i><span>Company</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}
            {((user && user.role_id === 1) || (categoryId && categoryId.includes(5))) && (
              <li className={`${activeTab === '/lifeinsurance' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Life Insurance">
                <Link to="/lifeinsurance" className='s-btn' onClick={handleLinkClick} >
                <i className="bi bi-bandaid me-3"></i><span>Life Insurance</span>
                </Link>
              </li>
            )}
            {((user && user.role_id === 1) || (categoryId && categoryId.includes(6))) && (
              <li className={`${activeTab === '/vehicleinsurance' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Vehicle Insurance" >
                <Link to="/vehicleinsurance" className='s-btn' onClick={handleLinkClick} >
                  <i className="bi bi-car-front me-3"></i><span>Vehicle Insurance</span>
                </Link>
              </li>
            )}
            {(user && user.role_id === 1) && 
              <li className={`${activeTab === '/user' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Role Manager">
                <Link to="/user" className='s-btn' onClick={handleLinkClick}>
                  <i className="bi bi-person me-3"></i><span>Role Manager</span>
                </Link>
              </li>
            }
            <li className={`${activeTab === '/' && 's-btn-active'}`} data-bs-toggle="tooltip" data-bs-placement="right" title="Logout">
              <Link to="/" className='s-btn' onClick={handleLinkClick}>
                <i className="bi bi-box-arrow-right me-3"></i><span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
