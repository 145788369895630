// src/dashboard/Dashboard.js
import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
// import './Dashboard.css';

const Dashboard = () => {
  return (
    <DashboardLayout>
      <h1>Dashboard...</h1>
    </DashboardLayout>
  );
};

export default Dashboard;