import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { login } from '../serviceAPI/userAPI';

const Login = () => {
  const navigate = useNavigate();
  const firstOtpInputRef = useRef(Array.from({ length: 6 }).map(() => React.createRef()));
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState(Array.from({ length: 6 }).map(() => ''));
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  // MSG91 configuration
  const configuration = {
    widgetId: "346776667162353937323330",  // Replace with your widgetId from MSG91
    tokenAuth: "426738TclvGmDmM66a8ec44P1",  // Replace with your tokenAuth from MSG91
    identifier: `91${mobileNumber}`,  // Mobile number must be in international format
    exposeMethods: true,  // Expose sendOtp, verifyOtp methods
    success: (data) => {
      console.log('OTP verification successful:', data);
    },
    failure: (error) => {
      console.error('OTP verification failed:', error);
    }
  };

  // Function to handle sending OTP
  const handleSendOtp = useCallback(() => {

    // const loggedIn = await login(mobileNumber);
    //   if (loggedIn) {
    //     setOtpVerified(true);
    //     navigate('/dashboard');
    //     // Redirect or do something after successful login
    //   } else {
    //     // Handle login failure
    //   }
    const regex = /^\d{10}$/;
    if (!regex.test(mobileNumber)) {
      alert('Mobile number invalid');
      return;
    }

    // Initialize the OTP process
    if (window.initSendOTP) {
      window.initSendOTP(configuration);
      setOtpSent(true); // Mark OTP as sent
    } else {
      console.error('initSendOTP is not defined');
    }
  }, [mobileNumber, configuration]);

  // Function to handle verifying OTP
  const handleVerifyOtp = useCallback(async () => {
    const code = otp.join('');

    // Call the verifyOtp method
    window.verifyOtp(code, async (data) => {
      console.log('OTP verified successfully:', data);
      const loggedIn = await login(mobileNumber);
      if (loggedIn) {
        setOtpVerified(true);
        navigate('/dashboard');
      } else {
        alert('Login failed');
      }
    }, (error) => {
      console.error('Error verifying OTP:', error);
      alert('OTP Verification Failed');
    });
  }, [mobileNumber, otp, navigate]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    if (value !== '' && index < newOtp.length - 1 && firstOtpInputRef.current[index + 1] && firstOtpInputRef.current[index + 1].current) {
      firstOtpInputRef.current[index + 1].current.focus();
    } else if (value === '' && index > 0 && firstOtpInputRef.current[index - 1] && firstOtpInputRef.current[index - 1].current) {
      firstOtpInputRef.current[index - 1].current.focus();
    }
    setOtp(newOtp);
  };

  return (
    <div className="login">
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-6 m-0 p-0'>
                <div className='login-img' style={{backgroundImage: "url('../assets/login-img.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", height: "100%"}}></div>
              </div>
              <div className='col-lg-6'>
                <div className='login-form'>
                  <h2 className='text-center'>Login with OTP</h2>
                  <div className='forms'>
                    <label htmlFor="otp" className='mb-2'>Enter Mobile Number</label>
                    <input type="tel" id='phoneNumber' value={mobileNumber} className="form-control mb-4" onChange={(e) => setMobileNumber(e.target.value)} disabled={otpSent} placeholder="Mobile Nmber"/>
                    {!otpSent && (
                    <button className="btn btn-white" id="send-otp-button" onClick={handleSendOtp} disabled={!mobileNumber || mobileNumber.length !== 10} >
                      Send OTP
                    </button>
                    )}
                    {otpSent && (
                    <div className="otp-section">
                      <div className="form-control">
                        <label htmlFor="otp">OTP</label>
                        <div className="otp-inputs">
                          {otp.map((digit, index) => (
                            <input key={index} type="text" ref={firstOtpInputRef.current[index]} maxLength={1} value={digit} onChange={(e) => handleOtpChange(index, e.target.value)} />
                          ))}
                        </div>
                      </div>
                      <button
                        className="btn btn-white" onClick={handleVerifyOtp} disabled={!otp.every(digit => digit)} >
                        Verify OTP
                      </button>
                    </div>
                    )}
                    {otpVerified && (
                    <div className="verified-message">
                      <p>OTP Verified!</p>
                    </div>
                    )}
                    <div id="recaptcha"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="login-container">
        <h2>Login with OTP</h2>
        <div className="input-group">
          <label htmlFor="phoneNumber">Mobile Number</label>
          <input
            type="text"
            id="phoneNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            disabled={otpSent}
          />
        </div>
        {!otpSent && (
          <button
            className="s-btn"
            id="send-otp-button"
            onClick={handleSendOtp}
            disabled={!mobileNumber || mobileNumber.length !== 10}
          >
            Send OTP
          </button>
        )}
        {otpSent && (
          <div className="otp-section">
            <div className="input-group">
              <label htmlFor="otp">OTP</label>
              <div className="otp-inputs">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    ref={firstOtpInputRef.current[index]}
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                  />
                ))}
              </div>
            </div>
            <button
              className="s-btn"
              onClick={handleVerifyOtp}
              disabled={!otp.every(digit => digit)}
            >
              Verify OTP
            </button>
          </div>
        )}
        {otpVerified && (
          <div className="verified-message">
            <p>OTP Verified!</p>
          </div>
        )}
        <div id="recaptcha"></div>
      </div> */}
    </div>
  );
};

export default Login;