import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './User.css';
import Popup from '../components/user-popup';
import Table from '../components/Table';
import DashboardLayout from '../components/DashboardLayout';
import { getRoleUserList } from '../serviceAPI/userAPI';

const User = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [editIndex, setEditIndex] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [userData, setUserData] = useState([]);
  const [heading, setHeading] = useState([]);

  useEffect(() => {
    getRoleUserData();
  }, []);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setIsPopupOpen(true);
  };

  const fetchApi = () => {
    getRoleUserData();
  };

  const getRoleUserData = async () => {
    const roleData = await getRoleUserList();
    if (roleData?.data && roleData?.data?.length) {
      setData(roleData?.data);
    }
    setHeading([{ key: 'username', head: 'User Name' }, { key: 'email', head: 'Email' }, { key: 'mobileNumber', head: 'Mobile Number' }, { key: 'categories_name', head: 'Categories' }]);
  };

  const handleChangeRowsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  return (
    <DashboardLayout>
      <div className='title-btn'>
        <h1>Role Manager</h1>
        <button className="btn dashboard-btn" onClick={togglePopup}>Add</button>
        {isPopupOpen && <Popup
          isOpen={isPopupOpen}
          onClose={togglePopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
        />}
      </div>
      {data.length > 0 &&
        <>
          <Table
            headings={heading}
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            handleEdit={handleEdit}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={handleSort}
            setItemsPerPage={setItemsPerPage}
          />
        </>
      }
    </DashboardLayout>
  );
};

export default User;
